<template>
	<div class="d-flex justify-space-between mt-5">
        <v-menu
          allow-overflow
          top
          origin="bottom center"
          transition="scale-transition"
          max-height="calc(100% - 24px)"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="text-none letter-spacing-0"
                  style="margin-left: -12px;"
                  text
                  small
                  dark
                  plain
                  v-bind="attrs"
                  v-on="on"
                >
                    {{ $vuetify.lang.t('$vuetify.footer.language') }}
                </v-btn>
            </template>

            <v-list 
              dense
            >
                <v-list-item-group
                  v-model="language"
                  mandatory
                >
                    <v-list-item 
                      dense
                      v-for="(item, i) in locales"
                      :key="i"
                    >
                        <v-list-item-title>{{ item.text }}</v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-menu>
        <div style="margin-right: -12px;">
            <v-btn
                v-if="$whitelabel.getPrivacyUrl()"
                class="text-none letter-spacing-0"
                text
                small
                dark
                plain
                :href="$whitelabel.getPrivacyUrl()"
                target="_blank"
            >
                {{ $vuetify.lang.t('$vuetify.footer.privacy') }}
            </v-btn>
            <v-btn
                v-if="$whitelabel.getTermsUrl()"
                class="text-none letter-spacing-0"
                text
                small
                dark
                plain
                :href="$whitelabel.getTermsUrl()"
                target="_blank"
            >
                {{ $vuetify.lang.t('$vuetify.footer.terms') }}
            </v-btn>
        </div>
    </div>
</template>

<script>
export default {
    name: "Footer",
    data: () => ({
        locales: [
            {id: 'en', text: 'English'},
            {id: 'id', text: 'Indonesia'}
        ]
    }),
    computed: {
        language: {
            get() {
                var findIndex = this.locales.findIndex((item) => item.id == this.$store.state.app.locale);
                    findIndex = findIndex < 0 ? 0 : findIndex;
                return findIndex;
            },
            set(value) {
                this.$store.dispatch('app/setLocale', { locale: this.locales[value].id });
            }
        }
    }
}
</script>